@import url('./button.scss');
@import url('./tenez.scss');

* {
  box-sizing: border-box;
}

body {
  font-family: Merriweather, serif;
  margin: 0;
  padding: 0;
  background-color: #000;
  overflow-x: hidden;
  overflow-y: hidden;

  &.free {
    overflow-y: scroll;
  }
}

.intro {
  height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 100;

  &.hidden {
    display: none;
  }

  .visual-wrapper {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background-image: url("../assets/images/post-intro.png");
    background-size: cover;
    clip-path: polygon(25% 49.5%, 75% 49.5%, 75% 50.5%, 25% 50.5%);
    transition: all .3s ease-out;

    &.loaded {
      transition: all 0.5s ease-out;
      clip-path: polygon(0% 49.5%, 100% 49.5%, 100% 50.5%, 0% 50.5%);
    }

    &.full {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      transition: all 1s ease-out;
    }
  }

  h1 {
    font-size: 36px;
    color: #fff;
    position: absolute;
    top: 160px;
    left: 128px;
    font-family: "tenez";
  }

  .percents {
    position: absolute;
    top: 50%;
    left: 9%;
    transform: translateY(-50%);
    font-size: 5rem;
    color: #fff;
    font-family: "tenez";

    @media screen and (max-width: 1024px) {
      top: 40%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 3rem;
    }
  }

  .paraph {
    position: absolute;
    top: 50%;
    right: 9%;
    transform: translateY(-50%);
    font-size: 5rem;
    color: #fff;
    font-family: "tenez";

    @media screen and (max-width: 1024px) {
      top: 53%;
      left: 50%;
      transform: translateX(-50%);
      font-size: 3rem;
      right: auto;
    }
  }

  .loader {
    position: absolute;
    width: 80%;
    height: 500px;
    top: 49%;
    left: 10%;
    background-color: #A09595;
    transition: all .3s ease-out;
  }
}

.main-container {
  opacity: 1;
  visibility: visible;
  transition: all .3s ease-out;
  position: absolute;
  z-index: 1000;
  width: 100%;

  &.hidden {
    opacity: 0;
    visibility: hidden;
  }

  .csc-canvas {
    height: 100vh;
    width: 100%;
    object-fit: cover !important;
  }
}


.subtitles {
  position: fixed;
  z-index: 10000;
  width: 100%;
  height: 100px;
  bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;

  >div {
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-out;
    width: 80%;
    max-width: 785px;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 15px;
    text-align: center;
    color: #fff;
    margin: auto;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);

    &.show {
      opacity: 1;
      visibility: visible;
    }

    &.eleven {
      background-color: #FFC30B;
      width: auto;
      padding: 10px 30px;
      border-radius: 14px;

      h3 {
        margin-top: 0;
        color: #000;
        font-family: 'Merriweather', serif;
        margin-bottom: 10px;
        font-size: 24px;
      }

      a {
        padding: 8px 30px;
        border-radius: 50px;
        border: 1px solid black;
        color: black;
        text-decoration: none;
        display: inline-block;
        transition: all .3s ease-out;
        font-size: 16px;

        &:hover {
          background-color: #000;
          color: #fff;
        }
      }
    }
  }
}

.splash {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  transition: all .3s ease-out;

  &.show {
    visibility: visible;
    opacity: 1;
  }

  .container {
    max-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    h2 {
      font-family: 'Open-Sans', sans-serif;
      font-weight: 300;
      font-size: 26px;
      text-align: center;
    }

    .paraph {
      width: 120px;
      height: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FECE00;
      border-radius: 50%;
      border: 1.5px solid #000;
      font-family: 'Merriweather', serif;
      font-size: 40px;
    }

    .chevrons {
      width: 57px;
      height: 57px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        border-left: 3px solid black;
        border-bottom: 3px solid black;
        transform: rotate(-45deg) translate(-10px, 10px);
        animation: bounce 1s infinite;
        left: 8px;
      }

      &::after {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        border-left: 3px solid black;
        border-bottom: 3px solid black;
        bottom: 0px;
        transform: rotate(-45deg);
        animation: bounce 1s infinite;
        left: 8px;
      }
    }
  }
}

.last {
  height: 100vh;
  padding-top: 10000px;
  position: relative;
}

.contact-part {
  background-color: #000;
  position: relative;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10001;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .3s ease-out;

  .modal {
    max-width: 800px;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    text-align: center;
    background-image: url('../assets/images/contact-background.png');
    max-width: 1240px;
    width: 80%;
    background-size: cover;
    padding: 24px;
    border-radius: 20px;
    overflow: hidden;
  }

  .contact-container {
    display: flex;
    border-radius: 20px;
    overflow: hidden;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }

    h3 {
      text-align: left;
      font-size: 38px;
      margin-bottom: 18px;
      margin-top: 8px;
    }

    .left {
      background-color: #000;
      flex: 1 1 33%;
      color: #fff;
      padding: 30px;

      ul {
        padding: 0 24px 0 0;
      }

      ul li {
        display: flex;
        align-items: center;
        margin-top: 12px;

        img {
          width: 40px;
          height: 40px;
        }

        a {
          color: #fff;
          text-decoration: none;
          text-align: left;
          padding-left: 20px;
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          display: inline-block;
          max-width: 250px;
          overflow-wrap: break-word;
        }
      }
    }

    .right {
      background-color: #fff;
      flex: 2 1 66%;
      padding: 30px;

      form {
        width: 100%;
        text-align: right;

        .success {
          color: #34CC81;
          font-size: 12px;
          text-align: left;
          margin-top: 6px;
          font-family: "Open Sans", sans-serif;
          opacity: 0;
          visibility: hidden;
          transition: all .2s ease-out;

          &.active {
            opacity: 1;
            visibility: visible;
          }
        }

        input, textarea {
          width: 100%;
          background-color: #F1F1F1;
          border: none;
          outline: none;
          margin-top: 12px;
          padding: 12px 14px;
          font-size: 16px;
          font-family: "Open Sans", sans-serif;

          &#send-button {
            width: auto;
            color: #fff;
            background-color: #34CC81;
            border-radius: 50px;
            font-weight: bold;
            padding: 8px 18px;
            cursor: pointer;
          }

          &.firstname {
            margin-top: 0;
          }
        }
      }
    }
  }

  &.active {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes bounce {
  0% {
    bottom: 5px;
  }

  25% {
    bottom: 10px;
  }

  50% {
    bottom: 12px;
  }

  75% {
    bottom: 17px;
  }

  100% {
    bottom: 0;
  }
}