body {
	--button-border-radius: 50%;
    --button-bg: black;
    --button-stroke-1: #fff;
    --button-stroke-2: #fff;
    --button-stroke-width: 1px;
    --button-text: #fff;
    --button-bg-hover: transparent;
    --button-stroke-hover-1: #fff;
    --button-stroke-hover-2: #fff;
    --button-stroke-width-hover: 1px;
    --button-text-hover: #fff;
    --color-text: #ffffff;
    --color-bg: #1e1e23;
    --color-link: #e20c6a;
    --color-link-hover: #5a5456;
}

.button {
    visibility: hidden;
    opacity: 0;
	cursor: pointer;
	-moz-appearance: none;
	-webkit-appearance: none;
	border: 0;
	color: var(--button-text);
	background: none;
	width: 70px;
	height: 70px;
	padding: 0;
	margin: 1rem;
	font-family: inherit;
	font-size: 12px;
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
    right: 12px;
    top: 12px;
    z-index: 100000;
    transition: all .3s ease-out;
}

.button.active {
    opacity: 1;
    visibility: visible;
}

.button:focus,
.button--hover {
	outline: none;
	border-color: var(--button-stroke-hover);
	color: var(--button-text-hover);
	background: var(--button-bg-hover);
}

.button__deco {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	border-width: var(--button-stroke-width);
	border-style: solid;
	border-radius: var(--button-border-radius);
    background-color: #000;
}

.button__deco--1 {
	border-color: var(--button-stroke-1);
}

.button--hover .button__deco--1 {
	border-color: var(--button-stroke-hover-1);
}

.button__deco--2 {
	border-color: var(--button-stroke-2);
}

.button--hover .button__deco--2 {
	border-color: var(--button-stroke-hover-2);
}

.button__text, 
.button__text-inner {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	position: relative;
	left: 0.5px;
}

.button__text {
	overflow: hidden;
}